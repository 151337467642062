import { Row, Col, Table, Card, message, Button } from 'antd';
import { useEffect, useState } from 'react';
import { DeleteOutlined } from "@ant-design/icons"
import { useSelector, useDispatch } from 'react-redux';
import ProposedBadge from '../components/badge';
import { getQueries } from "../store/action/queries";
import { getFinancialYears, changeSelectedFY, } from '../store/action/fy';
import { changeSelectedClient, getClients } from "../store/action/client";
import SearchBox from '../components/SearchBox';
import moment from "moment";
import dayjs from "dayjs";
import useFetch from "../_fetch";
import { user_role } from '../consts';
import PermissionModal from '../components/PermissionModal';
import { Loader } from "../components/loader";
import { useNavigate } from "react-router-dom";
// import Query from "./Query";
import { useQuery } from './AllQueries';
import { priority_order, fy_status_order } from "../consts";
import FYPrioritySelector from "../components/prioritySelector";
import FYStatusSelector from "../components/FYStatus";
import { ChangeQuery } from "../store/action/queries";
import Fyassign from "../components/querySidebar/fyAsing";
import {

  priorityUpdate,
  statusUpdate,
  updateData,
} from "../store/action/fy";

export default function Financialyears() {
  let dispatch = useDispatch();
  const placeholder = "Search Financial Years..."
  const [show, setShow] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [permissionModalOpen, setPermissionModalOpen] = useState(null);
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selected: selectedCompany } = useSelector(st => {
    return st.company
  });
  const { all: selectedClient } = useSelector(st => st.client);
  const { all: client } = useSelector(st => st.client);
  const [showData, setShowData] = useState([]);
  const { all: fy } = useSelector(st => st.fy);
  const [fyy, setFyy] = useState("");
  const user = useSelector(st => st.user)
  const _fetch = useFetch()
  const statusFilters = fy_status_order?.map((e) => ({ text: e, value: e }));
  const priorityFilters = priority_order?.map((e) => ({ text: e, value: e }));
  const navigate = useNavigate();
  const { selected } = useSelector((st) => st.fy);
  let query = useQuery("search");
  const handlePriorityUpdate = async (item, newPriority) => {
    if (item?.priority !== newPriority) {
      try {
        const payLoad = {
          fy: item?._id,
          priority: newPriority,
        };
        let updatedQuery = await _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
          method: "PATCH",
          body: payLoad,
        });
        let resJson = await updatedQuery.json();
        if (resJson.status === 200) {
          dispatch(await priorityUpdate(resJson.response));
          message.success("Priority updated successfully");
        } else {
          message.error("Some error occurred while updating priority");
        }
      } catch {
        message.error("Some error occurred while updating priority");
      }
    }
    return;
  };
  const handleStatusUpdate = async (item, newStatus) => {
    if (item.status !== newStatus) {
      try {
        const payLoad = {
          fy: item?._id,
          status: newStatus,
        };
        let updatedQuery = await _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
          method: "PATCH",
          body: payLoad,
        });
        let resJson = await updatedQuery.json();
        if (resJson.status === 200) {
          dispatch(await statusUpdate(resJson.response));
          message.success("Status updated successfully");
          await dispatch(ChangeQuery(resJson.response));
        } else {
          message.error("Some error occurred while updating status");
        }
      } catch {
        message.error("Some error occurred while updating status");
      }
    }
    return;
  };
  const handleFyUpdate = async (update) => {
    try {
      const payload = {
        ...update,
        fy: fyy,
      };
      let updatedQuery = await _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
        method: "PATCH",
        body: payload,
      });
      let resJson = await updatedQuery.json();
      if (resJson.status === 200) {
        dispatch(await updateData(resJson.response));
        message.success(`Assigned to ${resJson.response.assigned_to.first_name} ${resJson.response.assigned_to.last_name} successfully`);
      } else {
        message.error("Some error occurred while updating assigned to");
      }
    } catch (e) {
      console.log(e);
      message.error("Some error occurred while updating assigned to");
    }
  };
  const assigneeFilters = (dat) => {
    let map = {};
    let filter = [];
    dat.forEach((e) => {
      let name = `${e?.first_name} ${e?.last_name}`;
      if (!map[name]) {
        map[name] = 1;
        filter.push({ text: name, value: name });
      }
    });
    return filter;
  };

  const columnData = [
    {
      title: `Financial Year (${dataLength})`,
      key: "start",
      render: (record) => (
        record?.fy_status === 'PROPOSED' ? (

          <div style={{ display: 'flex' }}>
            <p style={{ fontWeight: "600" }} className="pointer m-2">
              {moment(record?.start).format('YYYY')}-{moment(record?.end).format('YY')}
            </p>
            <ProposedBadge content="proposed" color="#CC9900" bgColor="rgba(204, 153, 0, 0.1)" />
          </div>
        ) : (
          <p style={{ fontWeight: "600" }} className="pointer">
            {moment(record?.start).format('YYYY')}-{moment(record?.end).format('YY')}
          </p>
        )

      ),

      onCell: (record) => {
        return {
          onClick: (event) => {
            navigate(
              `/queries?fy=${record?._id}&entity=${record?._id}&company=${selectedCompany?._id}`
            );
          },
        }
      }

    },

    {
      title: `Queries`,
      key: "queries",
      render: (record) => (
        <p> {record?.query_count} </p>
      )
    },
    {
      title: "Priority",
      key: "priority",
      sorter: (a, b) => {
        const aa = priority_order.indexOf(a?.priority);
        const bb = priority_order.indexOf(b?.priority);
        return aa - bb;
      },
      render: (record) => (
        <FYPrioritySelector
          item={{
            _id: record?.fy_id,
            priority: record?.priority,
          }}
          handlePriorityUpdate={handlePriorityUpdate}
          isDisabled={user?.role === user_role?.COMPANY}
        />
      ),
      filters: priorityFilters,
      onFilter: (value, record) => record?.priority === value,
    },
    {
      title: "Status",
      key: "status",
      sorter: (a, b) => {
        const aa = fy_status_order?.indexOf(a?.status);
        const bb = fy_status_order?.indexOf(b?.status);
        return aa - bb;
      },
      render: (record) => (
        <FYStatusSelector
          item={{
            _id: record?.fy_id,
            status: record?.status,
          }}
          handleStatusUpdate={handleStatusUpdate}
          isDisabled={user?.role === user_role?.COMPANY}
        />
      ),
      filters: statusFilters,
      onFilter: (value, record) => record?.status === value,
    },
    {
      title: "Assigned To",
      key: "assigned_to",
      render: (record) => (
        <div
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
          onClick={() => setFyy(record?.fy_id)}
        >
          <Fyassign
            query={{
              assigned_to: {
                first_name: record?.first_name,
                last_name: record?.last_name,
              },
            }}
            className="p-2"
            handleFyUpdate={handleFyUpdate}
            role={""}
          />
        </div>
      ),
      filters: assigneeFilters(show),
      onFilter: (value, record) =>
        `${record?.first_name} ${record?.last_name}` === value,
    },
    {
      title: `Trash`,
      key: "trash",
      render: (record) => (
        <div>

          {
            user.role === user_role.ADMIN && (
              <Button
                type='text'
                className='ms-3'
                onClick={() => {
                  setPermissionModalOpen(record)
                }}
              >
                <DeleteOutlined size={15} />
              </Button>
            )
          }
        </div>
      )
    }
  ];

  async function initClientAndQuery() {
    if (selectedCompany) {
      try {
        setLoading(true);
        let clients = await dispatch(await getClients(selectedCompany._id));
        await dispatch(await getFinancialYears(Array.isArray(clients) && clients.length ? clients.map(e => e._id) : []));
        let get = await dispatch(getQueries(selectedCompany._id));
        setQueries(get)
      } catch (e) {
        message.error('Some error occurred while fetching data');
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }

  async function deleteFinancialyear() {

    try {
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
        method: 'DELETE',
        body: {
          id: permissionModalOpen._id,
          user_id: user?._id
        }
      });
      let resJson = await res.json();
      if (resJson.status === 200) {
        initClientAndQuery();
        message.success('Financial_year deleted successfully');
      } else {
        message.error(resJson.response);
      }
    } catch (err) {
      message.error('Some error occurred while deleting financial year');
      console.log(err);
    } finally {
      setPermissionModalOpen(null);
    }
  }


  useEffect(() => {
    if (selectedCompany?._id) {
      initClientAndQuery();
    }
  }, [selectedCompany])
  async function getDatas(_id) {
    try {

      let fyofselectedclient = fy?.filter(e => _id === e?.client_id.toString());
      let finaldata = fyofselectedclient?.map((data) => {
        let query = queries.filter(e => e?.financialYear == data?._id);

        return {
          ...data,
          query_count: query.length,
          _id: data?._id
        }
      });
      setShowData(finaldata)

      setDataLength(fyofselectedclient.length)
    } catch (e) {
      console.log('ERROR', e);
    }
  }

  useEffect(() => {
    if (query.get("entity") && fy.length) {
      getDatas(query.get("entity"))
    }
  }, [selectedClient, fy, client, queries])

  return (
    <>
      {
        loading ? <Loader /> :
          <div>
            <Row gutter={[24, 0]}>
              <Col xs={24} className="mb-<FySidebar 24">
                <Card
                  className='header-solid h-full'
                  bordered={true}
                  bodyStyle={{ paddingTop: 0 }}
                  title={[
                    <div className='d-flex flex-col'>
                      <div className="flex-fill search d-flex">
                        <div className="d-flex flex-fill justify-content-center">
                          <SearchBox all={client} setshow={setShow} name={placeholder} />
                        </div>
                      </div>
                    </div>
                  ]}
                >

                  <Col>
                    {
                      <Table
                        onRow={(record, rowIndex) => {

                          return {
                            onClick: async (event) => {
                              await dispatch(changeSelectedFY(record));
                            },
                          };
                        }}
                        columns={columnData}
                        dataSource={showData}
                        pagination={false}
                        sortDirections={["descend", "ascend"]}
                        loading={loading}
                      />

                    }
                  </Col>
                </Card>
              </Col>
            </Row>
            <PermissionModal
              visible={permissionModalOpen !== null}
              setVisible={setPermissionModalOpen}
              onSuccess={deleteFinancialyear}
              child={
                <p>Are you sure you want to delete this <b>financial year</b>? This will delete all the  <b>Queries</b>, and <b>Attachments</b> related to this Financial year.</p>
              }
            />
          </div>

      }
    </>

  );

}